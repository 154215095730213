/// <reference path="../../shared_controllers/socketService.ts" />
/// <reference path="../../shared_controllers/util.ts" />
/// <reference path="../../app.ts" />
/// <reference path="../../shared_controllers/common_controllers.ts" />
/// <reference path="../../services/projects.services.ts" />
/// <reference path="../../services/generic.services.ts" />

class projectsBackupsCtrl extends baseEntitiesCtrl {
    static $inject: Array<string> = ['$scope', '$location', '$compile', 'socketService', '$routeParams','toastr'];
    showsubflows: boolean;
    toastrObj :any;
    backup: any;
    projectsService: ProjectsServiceClass;
    genericServices: GenericServiceClass;
    selectedBackup :any = {};
    selectedCurrentBackup :any = {};
    backups : any = [];
    id: string;
    groupname: string;
    
    constructor(
        public $scope,
        public $location,
        public $compile,
        public socketService: socketServiceFactory,
        public $routeParams,
        public toastr
    ) {
        super($scope, $location, $compile, socketService);
        this.id=this.$routeParams.id;
        this.toastrObj = toastr;
        this.collection = 'workflows';
        this.basequery = 'group_id eq \''+this.id +'\'';
        var user:any = this.socketService.user; 
        this.projectsService = new ProjectsServiceClass(this.service.socket,this.service.namespace)
        this.genericServices = new GenericServiceClass(this.service.socket,this.service.namespace)

        if(!user.roles.find(R=>R._id == adminsRoleId ) && !user.roles.find(R=>R._id == rpaDesignerRoleId ) && !user.roles.find(R=>R._id == unattendedrobotRoleId ) ){    
            this.$location.path("/");
            this.$scope.$apply();
        }

        this.socketService.onSignedin(async () => {
            this.getBackupsPagingData();
            var currentProjectGroup : any= await this.genericServices.getbyid(this.id ,'workflows' ); 
            this.groupname=currentProjectGroup.name;

            
        });


    }


    selectBackup(BackupItem){
        this.selectedBackup =  BackupItem;
    }

    selectCurrent(BackupItem){
        this.selectedCurrentBackup =  BackupItem;
    }


    async setAsCurrent(){

        try{
            await this.projectsService.SetBackupAsCurrent(this.id , this.selectedCurrentBackup._id);
            this.getPagingData();
            this.toastrObj.success("Assigned as current successfully");
        }catch(ex){
            this.toastrObj.error(GetErrorMessage(ex));
        }
    }
    async ignoreSetAsCurrent(){

        try{
            this.selectedCurrentBackup.isCurrent = false;
        }catch(ex){
            this.toastrObj.error(GetErrorMessage(ex));
        }

    }



    async deleteProject(id) {
        if (!this.deletecounter) {
            this.deletecounter = 0;
        }
        this.loading = true;
        try {
         await this.projectsService.deleteBackupProject(id);
         this.entities.forEach((entity, idx) => {
            if (entity._id == id) {
                this.entities.splice(idx, 1);
            }
        })
        this.deletecounter++;
        this.loading = false;
        //if (this.deletecounter > 5 || this.entities.length == 0) {
        if (this.entities.length == 0) {
            this.page = 1;
            this.entities = [];
            this.deletecounter = 0;
            // this.loaddata();
        } else {
            this.loading = false;
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        }
       this.getPagingData();
       
        } catch (err) {
            handleError(this.$scope, err);
        }
        
    }

}